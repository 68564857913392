import axios from "axios";

export const getAndroidKeys = async (token, sortParams) => {
  const res = await axios.get(
    `https://backend.konekt.vip/androidKeys`,
    {
      params: sortParams,
      headers:{
        token:`bearer ${token}`
      },
    }
  );
  return res.data;
}


export const updateAndroidControls = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/androidKeys/controls`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const bindAndroidKey = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/androidKeys/bind`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const bindByGroup = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/bindByGroup`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}
export const bindByStore = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/bindByStore`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const bindAndroidToStore = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/androidKeys/bindStore`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const groupsToDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/updateGroups`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const removeAndroidbind = async (token, code) => {
  const res = await axios.delete(
    `https://backend.konekt.vip/androidKeys/unbind`,
    {
      params:{
        code
      },
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const refreshAndroidDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/refresh`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const sendCommandToDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/sendCommand`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const sendMessageToDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/sendMessage`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}
export const sendRotationToDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/sendRotation`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}

export const getDevicesDetails = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/androidKeys/getDeviceDetails`,
    {
      params,
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getAndroidDevicesFromDevice = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/device/getAndroidDeviceByDeviceId`,
    {
      params,
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getSingleAndroidDevice = async (token, params) => {

  const res = await axios.get(
    `https://backend.konekt.vip/androidKeys/getSingleDevice`,
    {
      params,
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const switchKioskMode = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidKeys/kioskMode`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  )
}

export const getScreenshot = async (token, data) => {
  const res = await axios.get(
    `https://backend.konekt.vip/androidkeys/getScreenshot`,
    {
      headers: {
        token: `bearer ${token}`
      },
      params:{
        mac: data.mac
      }
    }
  );
  return res.data;
}

export const getDeviceVersion = async (token, data) => {
  const res = await axios.get(
    `https://backend.konekt.vip/androidkeys/versionGroup`,
    {
      headers: {
        token: `bearer ${token}`
      },
      params:{
        mac: data.mac
      }
    }
  );
  return res.data;
}

export const updateDeviceVersion = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidkeys/versionGroup`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      },
    }
  );
  return res.data;
}

export const changePlaylist = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidkeys/changePlaylist`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      },
    }
  );
  return res.data;
}

export const getBarcodes = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/androidkeys/getBarcodes`,
    {
      headers:{
        token: `bearer ${token}`,
      },
      params
    }
  );
  return res.data;
}

export const setAndroidDeviceBarcodes = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/androidkeys/setBarcodes`,
    data,
    {
      headers:{
        token: `bearer ${token}`,
      }
    }
  )
}