//lasciate ogni speranza voi ch'entrate

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import DeviceListPage from './pages/DeviceListPage';
import { useEffect } from 'react';
import { authUserApi } from './api/auth';
import { loginUser, logoutUser, setSelectedStore, setUrl, setUserLoading } from './store';
import Sidebar from './components/layout/Sidebar';
import DeviceEditPage from './pages/DeviceEditPage';
import LicensePage from './pages/LicensePage';
import DisplayPage from './pages/DisplayPage';
import ItemsPage from './pages/ItemsPage';
import ItemEditPage from './pages/ItemEditPage';
import DisplayEditPage from './pages/DisplayEditPage';
import ScannerPage from './pages/QrScannerPage';
import { withTranslation } from 'react-i18next';
import TemplatesPage from './pages/TemplatesPage';
import ReplaceWithScanner from './pages/ReplaceWithScanner';
import Bottombar from './components/layout/Bottombar';
import DeviceDisplayCreatePage from './pages/DeviceDisplayCreatePage';
import DeviceDisplayEditPage from './pages/DeviceDisplayEditPage';
import ScannerBind from './pages/ScannerBind';
import QrScannerPage from './pages/QrScannerPage';
import PrintPage from './pages/PrintPage';
import MultiplePrintPage from './pages/MultiplePrintPage';
import PreviewTemplateWithItem from './pages/PreviewTemplateWithItem';
import CreateNewPrintableTemplate from './pages/CreateNewPrintableTemplate';
import UpdatePrintableTemplate from './pages/UpdatePrintableTemplate';
import PlaylistPage from './pages/PlaylistPage';
import PlaylistEditPage from './pages/PlaylistEditPage';
import PlaylistDisplayCreatePage from './pages/PlaylistDisplayCreatePage';
import PlaylistDisplayEditPage from './pages/PlaylistDisplayEditPage';
import CreateViewTemplate from './pages/CreateViewTemplate';
import ViewTemplatePages from './pages/ViewTemplatePages';
import TrailLogPage from './pages/TrailLogPage';
import AndroidKeyPage from './pages/AndroidKeyPage';
import ItemCreatePage from './pages/ItemCreatePage';
import AlertSettingsPage from './pages/AlertSettingsPage';
import AndroidDevicePage from './pages/AndroidDevicePage';
import AdminManagmentPage from './pages/AdminManagmentPage';
import GeneralDashboard from './pages/GeneralDashboard';
import StoreManagmentPage from './pages/StoreManagmentPage';
import FileMangmentPage from './pages/FileMangmentPage';
import PromotionSettingsPage from './pages/PromotionSettingsPage';
import VersionPage from './pages/VersionPage';
import VersionUploadPage from './pages/VersionUploadPage';
import EditStorePage from './pages/EditStorePage';
import GroupListPage from './pages/GroupListPage';
import CategoryPage from './pages/CategoryPage';
import AndroidDeviceSetBarcodesPage from './pages/AndroidDeviceSetBarcodesPage';



function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const authUser = async (token, url) => {
    dispatch(setUserLoading());
    try {
      const res = await authUserApi(token, url);
      dispatch(loginUser({...res, token}));
      dispatch(setSelectedStore(res.storeId));
      dispatch(setUrl(url));
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(localStorage.getItem('token') && !user.token) {
      authUser(localStorage.getItem('token'), localStorage.getItem("url")).catch(() =>{
        localStorage.removeItem('token');
        dispatch(logoutUser);
        window.location.reload(false);
      });
    }
  }, [user.token])


  if(!user.token) {
    return <Login/>;
  }

  return (
    <>
    <BrowserRouter>
      <div className='flex flex-row w-full h-full bg-white'>
        <Sidebar/>
        <div className='w-screen h-screen flex flex-col bg-white justify-start'>
          <div className='h-screen flex flex-col justify-start'>
            <Routes>
              <Route path='/' element={<DeviceListPage/>}/>
              <Route path='/scanner' element={<ScannerPage/>}/>
              <Route path='/replaceBarcodeWithScanner' element={<ReplaceWithScanner/>}/>
              <Route path='/licenses' element={<LicensePage/>}/>
              <Route path='/displays' element={<DisplayPage/>}/>
              <Route path='/templates' element={<TemplatesPage/>}/>
              <Route path='/displays/:displayId' element={<DisplayEditPage/>}/>
              <Route path='/editDevice/:deviceId' element={<DeviceEditPage/>}/>
              <Route path='/products' element={<ItemsPage/>}/>
              <Route path='/products/editProdcut/:itemCode' element={<ItemEditPage/>}/>
              <Route path='/products/createProduct/' element={<ItemCreatePage/>}/>
              <Route path='/device/createDisplay' element={<DeviceDisplayCreatePage/>}/>
              <Route path='/device/editDisplay/:displayIndex' element={<DeviceDisplayEditPage/>}/>
              <Route path='/scannerBind' element={<ScannerBind/>}/>
              <Route path='/qrScan' element={<QrScannerPage/>}/>
              <Route path='/print' element={<PrintPage/>}/>
              <Route path='/multiplePrint' element={<MultiplePrintPage/>}/>
              <Route path='/previewTemplate/:item' element={<PreviewTemplateWithItem/>}/>
              <Route path='/createPrintable' element={<CreateNewPrintableTemplate/>}/>
              <Route path='/editPrintable' element={<UpdatePrintableTemplate/>}/>
              <Route path='/playlistDashboard' element={<PlaylistPage/>} />
              <Route path='/editplaylist/:playlistId' element={<PlaylistEditPage/>} />
              <Route path='/addDisplayToPlaylist' element={<PlaylistDisplayCreatePage/>} />
              <Route path='/editDisplayToPlaylist/:displayIndex' element={<PlaylistDisplayEditPage/>} />
              <Route path='/createViewTemplate' element={<CreateViewTemplate/>} />
              <Route path='/ViewTemplates' element={<ViewTemplatePages/>} />
              <Route path='/trail' element={<TrailLogPage/>}/>
              <Route path='/androidkeys' element={<AndroidKeyPage/>}/>
              <Route path='/alertSettings' element={<AlertSettingsPage/>}/>
              <Route path='/androidDevice/:code' element={<AndroidDevicePage/>}/>
              <Route path='/adminManagement' element={<AdminManagmentPage/>}/>
              <Route path='/generalDashboard' element={<GeneralDashboard/>}/>
              <Route path="/PromoSettings" element={<PromotionSettingsPage/>} /> 
              <Route path='/stores' element={<StoreManagmentPage/>}/>
              <Route path='/uploads' element={<FileMangmentPage/>}/>
              <Route path='/version' element={<VersionPage/>}/>
              <Route path='/updateVersions' element={<VersionUploadPage/>}/>
              <Route path='/editStore/:storeId/' element={<EditStorePage/>}/>
              <Route path='/groupList' element={<GroupListPage/>}/>
              <Route path='/category' element={<CategoryPage/>}/>
              <Route path='/DeviceBarcodes/:storeId/:mac' element={<AndroidDeviceSetBarcodesPage/>}/>
            </Routes>
            <Bottombar/>
          </div>
        </div>
      </div>
    </BrowserRouter>
   </>
  );
}

export default withTranslation()(App);
