import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import ViewDetailsIcon from '../../static/view-detailsW.png';
import OnlineIcon from '../../static/green.png';
import OfflineIcon from '../../static/red.png';
import BrokenConnectIcon from '../../static/yell.png';
import RefershIcon from '../../static/refreshW.png';
import LoadingAnimation from '../../static/loading.gif';
import { getScreenshot, refreshAndroidDevice } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';
import PlaylistElement from '../playlistElements/PlaylistElement';
import { previewDevice } from '../../api/deviceApi';

const AndroidDeviceBox = ({androidDevice, deleteFunc}) => {

  const user = useSelector(state => state.user);
  const [refreshLoading,setRefreshLoading] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [preview, setPreview] = useState({
    type:"none",
    content:""
  });
  const [screenshotData, setScreenshotData] = useState("");
  
  const getPreviewData = async () => {
      try {
       
        const screenshotResponse = await getScreenshot(user.token, {mac: androidDevice.mac});

        if(screenshotResponse.screenshot) {
          setScreenshotData("data:image/png;base64," + screenshotResponse.screenshot);
        } 
        
        const prevData = await previewDevice(
          user.token,
          {deviceId: androidDevice.deviceId}
        );
        if(!prevData || prevData.length  < 1) {
          return setPreview({type:"none"});
        }
  
        for(let i = 0; i < prevData.length; i++) {
          if(prevData[i].type !== "video" && prevData[i].type !== "img" && prevData[i].type !== "image") {
            return setPreview({type:"viewTemplate"});
          }
        }
        if(prevData[0].type == "video") {
          return setPreview({type:"video", content:prevData[0].content});
        }
        if(prevData[0].type == "image" || prevData[0].type == "img") {
          return setPreview({type:"image", content:prevData[0].content});
        }
        return setPreview({type:"viewTemplate"});
      
        
      } catch (err) {
        console.log(err);
        setPreview({type:"none"});
  
      } finally {
        setLoadingPreview(false);
      }
    } 

  useEffect(() => {
      let intId;
      if(androidDevice.deviceId) {
        getPreviewData();
        intId = setInterval(() => {
          getPreviewData();
        }, 1000 * 60 * 5) 
      }
      else {
        setLoadingPreview(false);
      }
      return () => {
        clearInterval(intId);
      }
    }, [])
  return (
    <div className='relative  flex flex-col   bg-gray-400 rounded-md gap-2  x overflow-clip'>
      <button 
        onClick={deleteFunc}
        className=' top-[0.5%]  left-[94%] absolute text-gray-100 font-bold text-2xl hover:text-gray-300 transition-all hover:bg-red-500 px-2 rounded-lg'
      >
        X
      </button>
      <div className='flex flex-col items-start overflow-clip p-2'>
        <div className='flex items-start justify-start w-full mt-8'>
          <div className='flex flex-col w-[70%] items-start justify-start'>

            <div className='flex w-full gap-2 pr-2 items-center'>
              <h2 className='text-white font-bold'>Active:</h2>
              {!androidDevice.brokenConnect && <img
                src={androidDevice.connected ? OnlineIcon : OfflineIcon}
                className='w-6 mt-1'
              />}
              {androidDevice.brokenConnect && <img
                src={androidDevice.connected ? BrokenConnectIcon : OfflineIcon}
                className='w-6 mt-1'
              />}
              <Link to={`/androidDevice/${androidDevice.code}`}>
                <img src={ViewDetailsIcon} className='w-6' />
              </Link>
              {!refreshLoading ? <button
                onClick={async () => {
                  setRefreshLoading(true);
                  await refreshAndroidDevice(user.token,{mac: androidDevice.mac})
                  setRefreshLoading(false);
                }}
                >
                <img src={RefershIcon} className='w-6'/>
              </button>: <img src={LoadingAnimation} className='w-6' />}
            </div>
            <h2 className='text-white font-bold'>Code: {androidDevice.code}</h2>
            {androidDevice.name && <h2 className='text-white font-bold'>Name: {androidDevice.name}</h2>}
            <h2 className='text-white font-bold'>Store: {!androidDevice.storeName && androidDevice.storeId} {androidDevice.storeName && androidDevice.storeName}</h2>
            {androidDevice?.groups?.length > 0 &&<div className='flex w-full'>
              <h2 className='text-white font-bold'>Groups: [</h2>
                {androidDevice.groups.map(grp => 
                  <h2 className='text-white font-bold mx-1'>{grp}</h2>
                )}
              <h2 className='text-white font-bold '>]</h2>

            </div>}
          </div>
          <div className='flex flex-col w-[30%] items-start justify-start'>
            {!loadingPreview &&  screenshotData != "" &&  <div className='w-full h-full'>
              <img
                className='w-full h-full aspect-square'
                src={screenshotData}
              />
            </div>}
          </div>
        </div>
        
        
      </div>
     
    </div>
  )
}

export default AndroidDeviceBox