import React, { useEffect, useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import { getStoresApi } from '../../api/storesApi';
import { useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';
import AdminStoreBox from './AdminStoreBox';
import { createAdminApi, editAdminApi } from '../../api/userApi';
import { useTranslation } from 'react-i18next'

const EditAdminModal = ({setShowModal, admin}) => {
  const [newAdmin, setNewAdmin] = useState({...admin, changePassword: false, password:""});
  const user = useSelector(state => state.user);
  const {t} = useTranslation();
  const [stores, setStores] = useState([]);
  useEffect(() => {
    getStoresApi(user.token)
      .then(res => setStores(res))
      .catch(err => console.log(err))
  }, [])

  const addStore = (store) => {
    setNewAdmin(admn => {
      let newAdmn = {...admn}
      if(admn.stores.findIndex(str => str === store) == -1) {
        newAdmn.stores = [...admn.stores, store];
      }
      return newAdmn;
    });
  }

  const removeStore = (store) => {
    setNewAdmin(admn => {
      const newAdmn = {...admn};
      newAdmn.stores = newAdmn.stores.filter(str => str !== store);
      return newAdmn;
    })
  }
  
  const submitAdmin = async () => {
    try {
      console.log(newAdmin)
      if(!newAdmin.email) return;
      if(!newAdmin.storeId) return;
      const adminToSend = {...newAdmin};
      adminToSend.email = adminToSend.email.toLowerCase();
      await editAdminApi(user.token, adminToSend);
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  }
  console.log(newAdmin);
  return (
    <div>
       <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
         <div className="flex flex-col justify-center items-center w-full h-full overflow-scroll ">
            <div className="relative flex flex-col bg-white items-center rounded-lg shadow dark:bg-gray-700 w-[40%] p-5 overflow-scroll">
              <button
                className='cursor-pointer absolute left-[95%] z-20'
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
              <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
                <h2 className='text-4xl font-extrabold m-2'>Edit Admin Account</h2>
                <p
                className="w-full text-xl  text-black font-extrabold"
                >
                  Login Name
                </p>
                <input 
                  className='w-[100%] rounded-lg p-3  text-black font-bold border-2 bg-gray-400'
                  type='text'
                  value={newAdmin.email}
                  disabled={true}
                  name="code"
                  onChange={(e) => setNewAdmin(newAdmin => ({...newAdmin, email:e.target.value}))}
                />
              </div>
              <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
                <p
                  className="w-full text-lg  text-black font-bold"
                >
                  Name
                </p>
                <input 
                  className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                  type='text'
                  value={newAdmin.name}
                  name="code"
                  onChange={(e) => setNewAdmin(newAdmin => ({...newAdmin, name:e.target.value}))}
                />
              </div>
              <div className='flex w-full items-center gap-2'>
                <input
                  type='checkbox'
                  value={newAdmin.changePassword}
                  onClick={(e) => {
                    setNewAdmin(newAdmin => ({...newAdmin, changePassword: !newAdmin.changePassword}))
                  }}
                />
                <p
                  className=" text-lg  text-black font-bold"
                >
                  Change Password
                </p>
              </div>
             {newAdmin.changePassword && <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
                <p
                  className="w-full text-lg  text-black font-bold"
                >
                  Old Password
                </p>
                <input 
                  className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                  type='password'
                  value={newAdmin.password}
                  name="code"
                  onChange={(e) => setNewAdmin(newAdmin => ({...newAdmin, password:e.target.value}))}
                />
                <p
                  className="w-full text-lg  text-black font-bold"
                >
                  New Password
                </p>
                <input 
                  className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                  type='password'
                  value={newAdmin.newPassword}
                  name="code"
                  onChange={(e) => setNewAdmin(newAdmin => ({...newAdmin, password:e.target.value}))}
                />
              </div>}
              <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
                <p
                  className="w-full text-lg  text-black font-bold"
                >
                  Store ID
                </p>
                <Select
                  options={stores.map(store => ({label:store.storeId, value:store.storeId}))}
                  value={newAdmin.storeId ? {label: newAdmin.storeId, value: newAdmin.storeId} : {label:"select Store...", value:""}}
                  onChange={(selection)=> setNewAdmin(newAdmin => ({...newAdmin, storeId: selection.value}))}
                  classNames={{
                    menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                    menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
              <div className="relative flex justify-center items-center text-center w-full gap-2 mt-2">
                <input 
                  className='rounded-lg   text-black font-bold border-2 mt-1'
                  type='checkbox'
                  checked={newAdmin.superAdmin}
                  onClick={() => {
                    setNewAdmin(newAdmin => ({...newAdmin, superAdmin: !newAdmin.superAdmin}))
                  }}
                />
                <p
                  className=" text-lg  text-black font-bold"
                >
                  Super Admin
                </p>
              </div>
              {newAdmin.superAdmin && <>
                <div className="relative flex flex-col justify-start items-center text-center w-full gap-2 mt-5">
                <p
                  className="w-full text-lg  text-black font-bold"
                >
                  Add Stores
                </p>
                <Select
                  options={stores.map(store => ({label:store.storeId, value:store.storeId}))}
                  // value={newAdmin.storeId ? {label: newAdmin.storeId, value: newAdmin.storeId} : {label:"select Store...", value:""}}
                  onChange={(selection)=> {
                    addStore(selection.value)
                  }}
                  classNames={{
                    menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                    menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
              <div className="w-[100%] p-4 grid grid-cols-6 justify-start items-center gap-5 overflow-x-scroll bg-gray-200 m-2 rounded-xl">
                  {newAdmin?.stores?.map(str => <AdminStoreBox store={str} onRemoveStore={removeStore}/>)}
              </div>
              </>}
              <div className="flex items-center justify-center p-3 border-slate-200 rounded-b">
                <button
                  className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                  type="button"
                  onClick={submitAdmin}
                >
                  {t("send")}
                </button>
              </div>
            </div>
         </div>
       </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

    </div>
  )
}

export default EditAdminModal