import React, { useEffect, useState } from 'react'
import { getUsersApi } from '../api/userApi'
import { useSelector } from 'react-redux'
import Header from '../components/layout/Header';
import Admin from '../components/admin/Admin';
import AddIcon from '../static/updatePlaylist.png';
import CreateAdminModal from '../components/admin/CreateAdminModal';
import EditAdminModal from '../components/admin/EditAdminModal';
import DeleteAdminModal from '../components/admin/DeleteAdminModal';

const AdminManagmentPage = () => {

  const user = useSelector(state => state.user);
  const [admins, setAdmins] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState({
    show:false,
    admin:null
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show:false,
    admin: null
  });
  const fetchUserData = async () => {
    try {
      const res = await getUsersApi(user.token);
      setAdmins(res);
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if(!showAddModal && !showEditModal.show && !showDeleteModal.admin){
      fetchUserData();
    }
  }, [showAddModal, showEditModal, showDeleteModal]);
  

  return (
    <>
      {showAddModal && <CreateAdminModal setShowModal={setShowAddModal}/>}
      {showEditModal?.show && <EditAdminModal setShowModal={setShowEditModal} admin={showEditModal.admin}/>}
      {showDeleteModal?.show && <DeleteAdminModal setShowModal={setShowDeleteModal} admin={showDeleteModal.admin}/>}

      <div className='w-full h-full flex flex-col items-center overflow-scroll'>
        <div className='w-full flex flex-row items-center justify-center gap-5 mt-2'>
          <button
            onClick={() => setShowAddModal(true)} 
          >
            <img src={AddIcon} className='w-8'/>
          </button>
        </div>
        <table className="w-full items-center">
          <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2 border-gray-300">
            <tr>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                Email
              </th>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                Name
              </th>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"
              >
                Store
              </th>
              <th
                scope='col'
                className="px-6 py-4 hover:cursor-pointer"
              >
                Super Admin
              </th>
              <th
                scope='col'
                className="px-6 py-4 hover:cursor-pointer"
              >
                Store Access
              </th>
              <th 
                scope='col'
                className="px-6 py-4 hover:cursor-pointer"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {admins && admins.length > 0 && admins.map(admin => <Admin 
              admin={admin} 
              setShowEditModal={(show,admin) => {
                setShowEditModal({show, admin});
              }}
              setShowDeleteModal={(show, admin) => {
                setShowDeleteModal({show, admin});
              }}
            />)}
          </tbody>
        </table>
      </div>

    </>
  )
}

export default AdminManagmentPage