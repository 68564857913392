import React, { useState } from 'react'
import EditIcon from '../../static/stylus.png';
import DeleteIcon from '../../static/delete.png';
const Admin = ({admin, setShowEditModal, setShowDeleteModal}) => {

  return (
    <>
      <tr key={admin._id} className="w-full border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
        <td className="w-[25%] text-black font-bold border-r-2 p-2 ">
          {admin.email}
        </td>
        <td className="w-full text-black font-bold border-r-2 p-2 ">
          {admin.name}
        </td>
        <td className="w-full text-black font-bold border-r-2 p-2 ">
          {admin.storeId}
        </td>
        <td className="w-full text-black font-bold border-r-2 p-2 ">
          {admin.superAdmin ? "True" : "False"}
        </td>
        {
          admin.superAdmin 
          ? <td className="wtext-black font-bold border-r-2 p-2  flex w-full gap-2 justify-center">
            {admin.stores && admin.stores.length > 0 && admin.stores.map(store => <div>
              <h2>{store}</h2>
            </div>)}
            </td>
          :  <td className="text-black font-bold border-r-2 p-2 flex w-full justify-center ">
          {admin.storeId}
        </td>
        }
        <td className='w-full '>
          <div className='flex w-full items-center justify-center gap-2'>
            <img
              src={EditIcon}
              onClick={() => {
                setShowEditModal(true, admin);
              }}
            />
            <img
              src={DeleteIcon}
              onClick={() => {
                setShowDeleteModal(true, admin);
              }}
            />
          </div>
        </td>
      </tr>
    </>
  )
}

export default Admin