import { useEffect, useState } from "react"
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';
import { useSelector } from "react-redux";
import { bindByGroup, bindByStore } from "../../api/AndroidKeyApi";
import { getStoresApi } from "../../api/storesApi";
import Select from "react-tailwindcss-select";
import { getGroupListCall } from "../../api/GroupListApi";

const BindByGroupModal = ({setShowModal}) => {
  
  const [groupToSend, setGroupToSend] = useState("");
  const [adminStores, setAdminStores] = useState([]);
  const [fetchedGroups, setFetchedGroups] = useState([]);
  const [stores, setStores] = useState([]);

  const [error, setError] = useState("");
  const device = useSelector(state => state.device);
  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);

    const addStore = (store) => {
    setStores(strs => {
      if(strs && strs.findIndex(str => str == store) == -1) {
        return [...strs, store];
      }
      return strs;
    });
  }

  const removeStore = (store) => {
    setStores(strs => {
      let newStrs = [...strs].filter(strs => strs != store);
      return newStrs;
    });
  }

  const fetchStores = async () => {
    try {
      const res = await getStoresApi(user.token);
      setAdminStores(res);
    } catch (err) {
      console.log(err)
    }
  }

  const fetchGroups = async () => {
    try {
      const data = await getGroupListCall(user.token, {storeId: storeSelection});
      if(data?.list) {
        setFetchedGroups(data.list);
      }
      else {
        setFetchedGroups([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchGroups();
    fetchStores();
  }, []);

  const bindByGroupCall = async () => {
    try {
      if(groupToSend) {
        await bindByGroup(user.token, {
          group: groupToSend,
          deviceId: device.deviceId,
          stores: stores
        });
      }
      if(!groupToSend) {
        await bindByStore(user.token, {
          deviceId: device.deviceId,
          stores: stores
        });
      }
      setShowModal(false);
    } catch (err) {
      console.log(err);
      setError("Internal Server Error");
        return setTimeout(() => {
          setError("");
        }, 3000)
    }
  } 
  
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Store Select
              </p>
              <Select
                options={adminStores.map(store => ({label:`${store.storeId} (${store.name})`, value:store.storeId}))}
                value={{value:"...", label:"..."}}
                onChange={(selection)=> { addStore(selection.value)}}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                  menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
              <div className='p-4 w-[90%] grid grid-cols-2 md:grid-cols-3 rounded-2xl gap-2 bg-gray-500'>
                {stores.map(str => <div className='rounded-lg p-2 flex gap items-center justify-center bg-gray-400 overflow-scroll'>
                  <h2 className='text-white font-bold text-md'>{str}</h2>
                  <button 
                    className='font-extrabold text-xl mx-2 text-white hover:text-gray-200'
                    onClick={() => {
                      removeStore(str);
                    }}
                  >
                    X
                  </button>
                </div>)}
              </div>
                    <p
                      className="w-full text-lg  text-black font-bold"
                    >
                      Group Select
                    </p>
                    <Select
                      options={fetchedGroups?.length > 0 ?
                      fetchedGroups.map(grp => ({label:grp, value:grp}))
                      : []
                      }
                      value={groupToSend !== "" ? {label:groupToSend, value:groupToSend} : {lable:"Choose a group", value:"Choose a group"}}
                      onChange={(selection) => {
                        setGroupToSend(selection.value);
                      }}
                      classNames={{
                        menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                        menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                        listItem: ({ isSelected }) => (
                          `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                              isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                          }`
                        )
                      }}
                    />
              
            </div>
            <div className='w-full flex-col flex justify-center items-center'>
              <h2 className='text-red-500 font-bold m-2'>{error}</h2>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 ">
              <div className="flex flex-row justify-center gap-10 w-[100%]">
                <button
                  onClick={() => bindByGroupCall()}
                  className='border-2 p-1 border-[#2D5186] rounded-lg hover:bg-gray-100 transition-all'
                >
                  <img src={SaveIcon} className='w-8'/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default BindByGroupModal