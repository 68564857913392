import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getItem, searchItems } from '../api/itemsApi';
import { useSelector } from 'react-redux';
import Header from '../components/layout/Header';
import i18n from '../i18n';
import ItemInDevice from '../components/items/ItemInDevice';
import ItemResult from '../components/items/ItemResult';
import Search from '../static/search_black.png'
import Scan from '../static/scan.png'
import { getBarcodes, setAndroidDeviceBarcodes } from '../api/AndroidKeyApi';

const AndroidDeviceSetBarcodesPage = () => {
  const user = useSelector(state => state.user);
  const {mac, storeId} = useParams();
  const navigate = useNavigate();
  
  const [barcodeSearch, setBarcodeSearch] = useState(null);
  const [barcodes, setBarcodes] = useState([]);
  const [barcodeError, setBarcodeError] = useState("");
  const inputRef = useRef();
  const {t} = useTranslation();
  
  const onPickItem = async (barcode) => {
    inputRef.current.value =""
    try {
      const itemResult = await getItem(user.token, "", barcode, storeId);
      if(itemResult){
        setBarcodes(barcodes => {
          return [...barcodes, barcode ]
        });
      } else {
        setBarcodeError(t("barcodeNotFound"));
        setTimeout(() => {
          setBarcodeError("");
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setBarcodeError("Unknown Error");
      setTimeout(() => {
        setBarcodeError("");
      }, 2000)
    } finally {
      setBarcodeSearch(null)
    }
  }
  
  const fetchBarcodes = async () => {
    try {
      const res = await getBarcodes(user.token, {mac});
      setBarcodes(res);
    } catch (err) {
      console.log(err);
    }
  }

  const updateBarcodes = async () => {
    try {
      await setAndroidDeviceBarcodes(user.token, {
        barcodes,
        mac
      })
      navigate('/androidKeys');
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchBarcodes();
  }, []);

  const searchItemsCall = async () => {
    try {
      const result = await searchItems(user.token,"", inputRef.current.value,storeId);
      setBarcodeSearch(result)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
    <Header showBack={true} backTitle={"Devices"}/>
    <div 
      dir={i18n.language == "he" ? "rtl" :"ltr"}
      className="w-full h-screen my-6 overflow-scroll mt-[50px] pb-24"
    >
      <div className="flex flex-col w-full h-screen bg-white justify-start items-center overflow-scroll">
        <div className="w-full relative flex flex-col items-center">
            <div className='flex flex-col w-full md:w-[50%]  gap-2 p-2'>
              {barcodes && barcodes.length > 0 && <>
                <label
                  className="block text-md text-black font-bold"
                >
                  {t("barcodes")}
                </label>
                <div className='flex flex-col  gap-2 w-full overflow-scroll'>
                  {barcodes.map((barcode, barcodeIndex) => 
                    <ItemInDevice
                      barcode={barcode}
                      key={barcodeIndex}
                      allowReplace={true}
                      storeId={storeId}
                      onDelete={() => setBarcodes(barcodes => {
                        let newBarcodes = barcodes.filter((barcode, innerIndex) => barcodeIndex != innerIndex);
                        return newBarcodes
                      })}
                    />
                  )}
                </div>
              </>}
              <label
                className="block text-md text-black font-bold mx-2"
              >
                {t("addAbarcode")}
              </label>
              <div className='relative w-full'>
                <input
                  type="text"
                  name="org"
                  ref={inputRef}
                  className="h-10 pl-8 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
                />
                <div className='flex flex-col items-center relative w-full'>
                  {barcodeSearch && <ItemResult items={barcodeSearch} addItem={onPickItem}/>}
                </div>
                <h2 className='text-md text-red-500 font-bold'>{barcodeError}</h2>
                <button
                  className="absolute top-[10px] left-[1%]"
                  type="button"
                  onClick={() => searchItemsCall()}
                >
                  <img src={Search} width={25}/>
                </button>
              </div>
            </div>
            <div className="mt-6 flex flex-row justify-center">
              <button
                className="btn text-lg uppercase"
                onClick={updateBarcodes}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AndroidDeviceSetBarcodesPage