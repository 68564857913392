import grapesjs from 'grapesjs';
import GjsEditor, { Canvas, WithEditor, useEditorMaybe } from '@grapesjs/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { createViewTemplate, updateViewTemplate } from '../api/viewTemplatesApi';
import GrapesjsExport from 'grapesjs-plugin-export';
import grapesjsComponentCodeEditorMin from 'grapesjs-component-code-editor';
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css';
const CreateViewTemplate = () => {

  const navigate = useNavigate();
  const templateState = useSelector(state => state.template);
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const SaveButton = () => {
    const editor = useEditorMaybe();
    return (
      <div className='w-full  flex flex-row justify-center'>
      <button
        className='bg-blue-400 p-2 m-2 rounded-lg text-white font-bold' 
        onClick={async () => {
          const tempToReturn = `<style>${editor.getCss()}</style> ${editor.getHtml()}`;
          try {
            if(templateState.action === "create") {
              await createViewTemplate(user.token, {
                type: templateState.type,
                structure: templateState.structure,
                html: tempToReturn,
                storeId: selectedStore
              })
            }
            if(templateState.action === "edit") {
              await updateViewTemplate(user.token, {
                type: templateState.type,
                structure: templateState.structure,
                html: tempToReturn,
                storeId: selectedStore
              })
            }
          } catch (err) {
            console.log(err)
          }
          finally {
            navigate('/')
          }
        }}
      >
        Save
      </button>
    </div>
    );
  }
  
  const onEditor = (editor) => {

    editor.setComponents(templateState.html);
    editor.setStyle(templateState.html);
    const pn = editor.Panels;
    const panelViews = pn.addPanel({
      id: 'views'
    });
    panelViews.get('buttons').add([{
      attributes: {
         title: 'Open Code'
      },
      className: 'fa fa-file-code-o',
      command: 'open-code',
      togglable: false, //do not close when button is clicked again
      id: 'open-code'
    }]);
  }
  useEffect(() => {
    if(!templateState.action) {
      navigate('/')
    }
  }, [templateState])
  return (
      <GjsEditor
        className="gjs-custom-editor text-white bg-slate-900"
        grapesjs={grapesjs}
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        options={{
          height: '95vh',
          storageManager: false,
        }}
        plugins={[
          {
            id: 'gjs-blocks-basic',
            src: 'https://unpkg.com/grapesjs-blocks-basic',
          },
          GrapesjsExport,
          grapesjsComponentCodeEditorMin
        ]}
        onEditor={onEditor}
      >
        <WithEditor>
          <SaveButton/>
        </WithEditor>
      </GjsEditor>
  )
}

export default CreateViewTemplate